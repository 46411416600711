@import '../../../../variables.scss';

.container {
  .loadingContainer {
    position: absolute;
    top: 22em;
    left: 0;
    right: 0;
  }

  &.refreshing .rolesTableContainer table {
    visibility: hidden;
  }

  .rolesTableContainer {
    width: 100%;

    th,
    td {
      vertical-align: middle;
      white-space: nowrap;

      &.activeColumn {
        text-align: left;
      }
    }

    td.editColumn {
      text-align: right;
      width: 1px;
      padding: 0 0 0 0.5em;

      & > button {
        padding: 0.5em;
        margin: 0;
      }

      & > * {
        visibility: hidden;
      }
    }

    tr:hover > td {
      background-color: $secondaryButtonBackgroundColor;

      &:not(.editColumn) {
        cursor: pointer;
      }

      &.editColumn > * {
        visibility: visible;
      }
    }
  }
}

.noResultsContainer {
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  margin-top: 2em;
}

.searchInput {
  margin: 1em 0 2em 0;
}

.filterContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: -1em 0 1em 0;

  label {
    margin: 0 0 0 0.5em;
    font-size: 1em;
  }
}

.sortIconContainer {
  margin: 0 0.5em;
}

.moreButtonContainer {
  width: 100%;
  text-align: center;

  .moreButton {
    min-width: 10em;
  }
}

@include for-phone-only {
  .searchInput {
    :global(.input-group-prepend) {
      display: none;
    }

    input {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
    }
  }

  .moreButtonContainer {
    margin-top: 2em;
  }
}
