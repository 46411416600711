@import '../../variables.scss';

.modal {
  padding: 2em;
}

.fullWidth {
  max-width: 90% !important;
}

@media only screen and (max-width: 800px) {
  .fullWidth {
    max-width: 100% !important;
  }
}

.modalBody {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contentContainer {
  width: 100%;

  &.saving,
  &.success {
    visibility: hidden;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

.successContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: #d4edda;

  > * {
    border: none;
  }
}

.errorMessage {
  margin-top: 2em;
  margin-bottom: 0.5em;
  width: 100%;
  justify-content: center;
}
