@import '../../variables.scss';

.modal {
  max-width: 40em;
}

.titleIcon {
  display: inline-block;
  width: 1em;
  margin-top: -0.2em;
  margin-right: 0.25em;
}

.modalContentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .select {
    width: 100%;
  }
}
