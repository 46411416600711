@import '../../../../../../variables.scss';
@import '../../../../../../components/Pipe/animations.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;

  .balancerMainPipeHorizontal {
    position: relative;
    width: 28em;
    border-radius: 2em;
    @include pipe-flows-right();
  }

  > :not(:last-child) {
    margin-bottom: 1em;
  }

  .queueContainer {
    flex: 1;
    align-self: stretch;
    display: flex;
    justify-content: stretch;
    width: 28em;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    position: relative;

    .queueLane {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;

      .queueLaneIcon {
        height: 1.25em;
        width: 100%;
        text-align: center;
        padding-bottom: 0.125em;
      }

      .queueLaneTitle {
        border-bottom: 1px solid $componentAutomaticOnColor;
        width: 100%;
        text-align: center;
        padding-bottom: 0.125em;
      }

      .pumpName {
        position: relative;
        top: 0.15em;
        font-size: 0.977em;
        height: 1.629em;
        width: 1.629em;
        text-align: center;
        margin: 0.25em auto 0;
        border-radius: 50%;
        border: 1px solid $componentAutomaticOnColor;
        background-color: $componentAutomaticOffColor;
        z-index: 3;
      }

      .pumpOutPipeVertical {
        height: 1em;
        z-index: 2;
        @include pipe-flows-down();
      }

      .queueItemsContainer {
        position: relative;
        flex: 1;
        width: 80%;
        overflow: hidden;

        &::before {
          position: absolute;
          content: '';
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 1px;
          margin: 0 auto;
          border-left: 1px dashed $componentAutomaticOnColor;
        }

        .queueItem {
          --queue-item-color: #{$componentAutomaticOnColor};

          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          background-color: var(--queue-item-color);
          border-radius: 0.25em;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .queueItemDisplay {
          --top-offset: -1.4em;
          --height-offset: 2.8em;

          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          .timeLeftToFinish {
            white-space: nowrap;
          }

          .timeLeftToStart {
            white-space: nowrap;
          }
        }
      }

      .pumpContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top: 1px solid $componentAutomaticOnColor;
        width: 100%;
        padding-top: 0.25em;

        &.disabled .pumpUnknownIcon {
          visibility: hidden;
        }
      }
    }
  }
}
