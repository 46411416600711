@import '../../variables.scss';

.container {
  width: 100%;
  display: block;
  height: auto;

  .permissionContainer {
    margin-top: 0.5em;
    padding-bottom: 0.5em;

    &:not(:last-child) {
      border-bottom: 1px solid $componentAutomaticOffColor;
      margin-bottom: 0.5em;
    }
  }

  .switchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 0.57em;
    }

    .switchLabel {
      flex: 1 0 9em;
      margin-bottom: 0;
      text-align: left;
      color: $buttonTextColor;

      .helpTextIcon {
        margin: 0.2em 0 0 0.75em;
        color: $componentAutomaticOnColor;
        cursor: pointer;

        transition: color 0.25s linear;

        &:hover {
          color: $appTextColor;
        }
      }
    }
  }
}
