.modal {
  max-width: 35em;
}

.titleContainer {
  display: flex;
}

.icon {
  margin-right: 0.5em;
}
