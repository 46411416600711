@import '../../../../variables.scss';

.container {
  display: flex;
  justify-content: center;
  padding: 1.14em;
  height: 4.071em;
  background-color: $navigationBackgroundColor;
}

.kalera {
  width: 6.514em;
  margin-right: 20px;
}

.version {
  margin: 0 7px 0;
}
