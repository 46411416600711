@import '../../variables.scss';

.modal {
  max-width: 50em;
}

.modalContentContainer {
  min-height: 10em;

  :global(.invalid-feedback) {
    display: block;
    visibility: hidden;
    height: 1rem;
  }

  :global(.is-invalid) ~ :global(.invalid-feedback) {
    visibility: visible;
  }
}
