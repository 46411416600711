.container {
  position: relative;
}

.hasUnknownValue {
  opacity: 0.5;
}

.icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  color: #a9a9a9;

  > svg {
    width: 25%;

    > path {
      fill: currentColor;
    }
  }
}
