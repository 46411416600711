@import '../../variables.scss';

.modal {
  max-width: 50em;
}

.modalContentContainer {
  min-height: 10em;
  max-height: 69vh;

  @include for-phone-only {
    max-height: 50vh;
  }

  :global(.invalid-feedback) {
    display: block;
    visibility: hidden;
    height: 1rem;
  }

  :global(.is-invalid) ~ :global(.invalid-feedback) {
    visibility: visible;
  }
}

.permissionFormGroup {
  width: 100%;
}
