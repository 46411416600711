@import '../../variables.scss';

.button {
  position: relative;
  font-size: 1em;
  padding: 0;
  background-color: transparent !important;
  border-color: transparent !important;

  &:global(:not(.active)) {
    .hexagonInner {
      color: transparent;
    }
  }

  &.needsAttentionCritical {
    animation: needsAttentionCriticalPulse 500ms infinite;

    .hexagon {
      animation: needsAttentionCriticalPulse 500ms infinite;
    }

    .hexagonInner {
      animation: needsAttentionInactiveInnerPulse 500ms infinite;
    }

    &:global(.active) {
      .hexagonInner {
        animation: needsAttentionCriticalPulse 500ms infinite;
      }
    }
  }

  &.needsAttentionLow {
    .hexagon {
      color: $dangerColor;
    }

    &:global(.active) {
      .hexagonInner {
        color: $dangerColor;
      }
    }
  }

  .hexagon,
  .hexagonInner,
  .content {
    transition: color #{$buttonTransition}, opacity #{$buttonTransition};
  }

  &:global(.active):not(.needsAttentionCritical):not(.needsAttentionLow) {
    .hexagonInner {
      color: $componentAutomaticOnColor;
    }
  }

  &:hover,
  &:focus,
  &:global(.active) {
    .hexagon,
    .content {
      opacity: 1;
    }
  }

  .hexagon,
  .content {
    opacity: 0.65;
  }

  .hexagon {
    color: $componentAutomaticOnColor;
  }

  .content {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0.65;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: $buttonTextColor;

    .label {
      text-transform: uppercase;
      font-size: 0.5em;
      margin-bottom: -0.5em;
    }

    .name {
      text-transform: uppercase;
      font-size: 1.5em;
    }
  }
}

@keyframes needsAttentionCriticalPulse {
  0% {
    color: $componentAutomaticOnColor;
  }
  50% {
    color: $dangerColor;
  }
  100% {
    color: $componentAutomaticOnColor;
  }
}

@keyframes needsAttentionCriticalInactiveInnerPulse {
  0% {
    color: transparent;
  }
  50% {
    color: $dangerColor;
  }
  100% {
    color: transparent;
  }
}
