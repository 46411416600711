@import '../../variables.scss';

.container {
  position: relative;
  flex: 1 1 100%;
  width: 100%;
  max-width: 75em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navigation {
  width: 100%;
  margin-bottom: $panelMargin;
  @include panel();
  padding: 0.5em;
}

.detailContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 40.4em;
}

@include for-tablet-portrait-down {
  .container {
    justify-content: flex-start;
  }

  .detailContainer {
    min-height: calc(100vh - 366px);
  }
}
