@import '../../variables.scss';
@import '../../components/Pipe/animations.scss';

$pipeOffset: $dropSize / 2;
$pipeOffsetDelay: -1.25s;

.photoperiodContainer {
  position: absolute;
  left: 1.5em;
  top: 9em;
}

.roSkidContainer {
  display: none;
}

.reverseOsmosisPipe {
  display: none;
}

.roSystem {
  display: none;
}

.roSystemValve {
  display: none;
}

.roSystemValvePipe {
  display: none;
}

.stackTankInletPipe {
  display: none;
}

@media only screen and (min-width: 900px) {
  .roSkidContainer {
    display: inline-block;
    position: absolute;
    width: 15rem;
    min-width: 15rem;
    height: 15rem;
    min-height: 15rem;
    /*transform: translate(3.5rem, -1rem);*/
    z-index: 0;
    left: 0;
    margin-left: 5em;
    margin-bottom: 0.5em;
  }

  .reverseOsmosisPipe {
    display: inline-block;
    position: absolute;
    --water-color: #{$pureWaterColor};
    $height: 4.55em;
    @include pipe-flows-down();
    height: ($height + $dropSize) !important;
    z-index: 2;
    margin-bottom: $dropSize * -1;
    /*transform: translate(1.25rem, 2rem);*/
    transform: translate(250%, 35%);
  }

  .roSystem {
    display: inline-block;
    position: absolute;
    z-index: 3;
  }

  .roSystemValve {
    display: inline-block;
    position: absolute;
    z-index: 3;
    /*transform: translate(0.25rem, 7rem);*/
    transform: translate(15%, 275%);
  }

  .roSystemValvePipe {
    display: inline-block;
    position: absolute;
    --water-color: #{$pureWaterColor};
    $height: 3.5em;
    @include pipe-flows-down();
    height: ($height + $dropSize) !important;
    z-index: 2;
    margin-bottom: $dropSize * -1;
    /*transform: translate(1.25rem, 9.5rem);*/
    transform: translate(275%, 225%);
  }

  .stackTankInletPipe {
    display: inline-block;
    position: absolute;
    --water-color: #{$pureWaterColor};
    $height: 12em;
    @include pipe-flows-down();
    height: ($height + $dropSize) !important;
    z-index: 3;
    margin-bottom: $dropSize * -1;
    /*transform: translate(7.7rem, 6.8rem) rotate(270deg);*/
    transform: translate(1500%, 55%) rotate(270deg);
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @include panel();
}

.closeButton {
  top: 0;
  z-index: 1;
}

.lightScheduleContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.71em;
  position: relative;
}

.levelStatusContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.75em;
}

.statusContainer {
  margin-bottom: 1.71em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stackStatus {
  width: 10.586em;
  height: 32.571em;
}

@include for-tablet-portrait-down {
  .lightScheduleContainer {
    flex-direction: column;
    align-items: center;
  }
  .lightRecipe {
    margin-top: 1.5em;
  }

  .photoperiodContainer {
    position: absolute;
    left: 1.5em;
    top: 1.5em;
  }

  .stackStatus {
    margin-bottom: -1.2em;
    height: 20em;
  }

  .stackTank {
    display: none;
  }
}

@include for-tablet-landscape-up {
  .container {
    max-width: 77.357em;
  }

  .lightScheduleContainer {
    margin-bottom: 2.28em;
  }

  .levelsContainer {
    order: 3;
  }

  .levelStatusContainer {
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
  }

  .lightRecipe {
    position: absolute;
    left: 0;
  }

  .statusContainer {
    margin: 0 3.71em;
    order: 2;
  }

  .stackStatus {
    height: 36.317em;
  }
}
