@import '../../../../variables.scss';

.form {
  margin-bottom: 1em;

  select {
    &[multiple] {
      overflow-y: auto;
      height: auto !important;
    }

    .navigationOption {
      cursor: pointer;

      &:hover {
        color: white;
        background-color: $secondaryButtonBackgroundColor;
      }
    }
  }
}

.buttonContainer {
  text-align: right;
}

.facilitiesContainer {
  display: flex;
  flex-direction: column;
  height: auto;

  a {
    font-size: 1rem;
    color: $disabledFormControlTextColor;

    &:hover {
      color: adjust-color(
        $color: $disabledFormControlTextColor,
        $lightness: 50%
      );
    }
  }
}
