@import '../../variables.scss';

.container {
  position: relative;
  width: 2.443em;
  height: 2.443em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  &.unknown {
    transform: scale(1, 1);
    opacity: 1;
  }

  button {
    width: inherit;
    height: inherit;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.automaticMode {
      @include button($componentAutomaticOffColor);
    }

    &.manualMode {
      @include button($componentManualOffColor);
    }

    &.on {
      &.automaticMode {
        @include button($componentAutomaticOnColor);
      }

      &.manualMode {
        @include button($componentManualOnColor);
      }

      .pump {
        svg {
          fill: #fff;
        }
      }
    }
  }
}

.pump {
  width: 100%;
  height: 100%;
  padding: 0;
  background: none !important;
  border: none;
  box-shadow: none;

  svg {
    fill: #ccc;
  }
}

.pumpUnknownIcon {
  visibility: hidden;
}

.unknownValueOverlayIcon {
  opacity: 0.75;
  pointer-events: none;
  transform: scale(1, 1);

  & > svg {
    width: 40%;
  }
}
