@import '../../variables.scss';

.indicator {
  display: flex;
  z-index: auto;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 1.8em;
  height: 1.8em;
  font-size: 0.7em;
  color: $navigationBackgroundColor;
  background-color: $componentAutomaticOffColor;
  position: relative;

  $arrow-length: 0.65em;
  $arrow-height: 0.35em;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin: auto 0;
    width: 0;
    height: 0;
    border-top: $arrow-height solid transparent;
    border-bottom: $arrow-height solid transparent;
  }

  @mixin colors($color) {
    background-color: $color;
    &.pointerLeft:after {
      border-right: $arrow-length solid $color;
      left: -0.5em;
    }
    &.pointerRight:after {
      border-left: $arrow-length solid $color;
      right: -0.5em;
    }
  }

  &.enabled {
    @include colors($dangerColor);
  }

  &.disabled {
    @include colors($componentAutomaticOffColor);
  }
}
