@import '../../../../../../variables.scss';
@import '../../../../../../components/Pipe/animations.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  > :not(:last-child) {
    margin-bottom: 1em;
  }

  .samplerPipeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: right;

    .updatedAtTimestamp {
      font-size: 0.875em;
      font-style: italic;
      text-align: right;
      margin: 0 0 0.25em;
    }

    .samplerMainPipeHorizontal {
      position: relative;
      width: 28em;
      border-radius: 2em;
      @include pipe-flows-right();
    }
  }

  .nutrientMixerCommandsContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    & > :not(:last-child) {
      margin-right: 1em;
    }

    button {
      width: 10.5em !important;
    }
  }

  .gauges {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.25em;

    .gauge {
      font-size: 0.55em;
      cursor: pointer;
    }
  }
}
