@import '../../../../variables.scss';

.ozoneSystemConfigurationPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > :not(:last-child) {
    margin-bottom: 1em;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  > :not(:last-child) {
    margin-bottom: 1em;
  }

  .zoneNavigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1.5em;

    .zoneButton {
      font-size: 1.25em;
      margin-bottom: 0.5em;
    }
  }

  .zoneContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > :not(:last-child) {
      margin: 0 0 2em 0;
    }

    .zoneConfigurationPanel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > :not(:last-child) {
        margin-bottom: 1em;
      }
    }

    .pumps {
      display: flex;
      justify-content: center;
      align-items: center;

      > :not(:last-child) {
        margin: 0 1em 0 0;
      }

      @include for-tablet-portrait-down {
        flex-direction: column;
        > :not(:last-child) {
          margin: 0 0 1em 0;
        }
      }

      .pumpContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include for-tablet-portrait-down {
          flex-direction: row;
          margin-right: 3.5em;
        }

        .pumpSettingLabel {
          text-align: right;
          @include for-tablet-portrait-down {
            text-align: center;
          }
        }

        button.indicator {
          transition: background-color #{$buttonTransition};

          &,
          &:focus {
            border: none;
            outline: none;
          }
        }

        .indicator {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          width: 2em;
          height: 2em;
          font-size: 1.5em;

          &:not(:last-child) {
            margin: 0 0 0.3em 0;
          }

          @include for-tablet-portrait-down {
            &:not(:last-child) {
              margin: 0 0.5em 0 0;
            }
          }

          &.placeholder {
            visibility: hidden;
          }

          &.pump {
            background-color: $componentAutomaticOffColor;
          }

          &.activePumpSetting {
            color: $navigationBackgroundColor;
            background-color: $successColor;

            &:focus,
            &:hover {
              background-color: adjust-color(
                $color: $successColor,
                $lightness: 10%
              );
            }
          }

          &.inactivePumpSetting {
            color: $navigationBackgroundColor;
            background-color: $dangerColor;

            &:focus,
            &:hover {
              background-color: adjust-color(
                $color: $dangerColor,
                $lightness: 10%
              );
            }
          }
        }
      }
    }
  }
}
