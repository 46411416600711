@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import './variables.scss';

:root {
  --water-color: #{$waterColor};
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 0.2em;
  height: 0.2em;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  font-size: 0.875rem;
  font-family: 'Ubuntu';
}

body {
  background-color: $appBackgroundColor;
  color: $appTextColor;
}

.h5,
h5 {
  font-size: 1.43em;
}

.h6,
h6 {
  font-size: 1.1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.6em;
}

a,
.btn-link {
  color: #a9a9a9;
}

a:hover,
.btn-link:hover,
.btn-link:focus {
  color: $hoverTextColor;
  text-decoration: none;
}

.form-group {
  margin-bottom: 0.6rem;
}

.border-disable {
  border-color: #555555 !important;
}

.modal {
  color: #a9a9a9;
}

.modal-body {
  padding: 1.2em;
}

.modal-dialog {
  display: flex;
  align-items: center;
  height: inherit;
  margin: auto;
}

.modal-header,
.modal-footer {
  border-color: #444444;
}

.modal-content {
  background-color: $navigationBackgroundColor;
  border-radius: 1rem;
}

.modal-header button.close {
  color: white;
}

.modal-header button.close:focus {
  outline: initial;
}

.modal-footer button {
  min-width: 5rem;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25em;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25em;
}

.modal-footer button {
  min-width: 6em;
}

.react-switch-bg {
  border-radius: 0 !important;
  border-radius: 0.25rem !important;
}

.react-switch-handle {
  border-radius: 0.15rem !important;
}

.bg-primary {
  background-color: $primaryButtonBackgroundColor !important;
}

.btn-primary {
  @include button($primaryButtonBackgroundColor);
}

.btn-secondary {
  @include button($secondaryButtonBackgroundColor);
}

.text-primary {
  color: $primaryButtonBackgroundColor !important;
}

.border-primary {
  border-color: $primaryButtonBackgroundColor !important;
}

.btn {
  font-size: 1em;
  border-radius: 0.35em;
  color: $buttonTextColor;
}

.btn-icon {
  margin-left: -0.5em;
}

button.rounded,
.btn.rounded {
  border-radius: 2em !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9em;
  color: #dddddddd;
}

.btn-group.rounded {
  button,
  .btn {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.9em;
    color: #dddddddd;

    &:first-child {
      border-top-left-radius: 2em !important;
      border-bottom-left-radius: 2em !important;
    }

    &:last-child {
      border-top-right-radius: 2em !important;
      border-bottom-right-radius: 2em !important;
    }
  }
}

.form-control,
.input-group-text {
  font-size: 1em;
}

.form-control {
  &:disabled,
  &.disabled,
  &[readonly] {
    background-color: $disabledFormControlBackgroundColor;
    border-color: $disabledFormControlBorderColor;

    &,
    & option {
      color: $disabledFormControlTextColor;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.table-dark {
  background-color: initial;
}

.input-group-text {
  color: $buttonTextColor;
  border-color: $secondaryButtonBackgroundColor;
  background-color: $secondaryButtonBackgroundColor;
}

.answerTypeIndicator {
  position: absolute;
  font-size: 10pt;
  background-color: rgba(255, 128, 0, 0.3);
  color: rgb(200, 0, 0);
  padding: 0.2em 0.5em 0.1em;
  border: solid rgb(200, 0, 0) 1px;
  fill-opacity: 0.2;
  display: none;
  z-index: 999999;
}

.showing {
  display: block;
}
