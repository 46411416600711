@import '../../variables.scss';

.container {
  position: relative;
  width: 2.443em;
  height: 2.443em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  &.unknown {
    transform: scale(1, 1);
    opacity: 1;
  }

  button {
    width: inherit;
    height: inherit;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    @include button($componentManualOffColor);

    &.open {
      @include button($componentManualOnColor);

      .valve {
        svg {
          fill: #fff;
        }
      }
    }

    &.needsAttention,
    &.open.needsAttention {
      @include button($componentAutomaticOffColor);
      border-color: $componentAutomaticOffColor;
      animation: needsAttentionPulse 500ms infinite;
    }
  }
}

.valve {
  width: 1.85em;
  height: 1.85em;
  padding: 0;
  background: none !important;
  border: none;
  box-shadow: none;

  svg {
    fill: #ccc;
  }
}

.valveUnknownIcon {
  visibility: hidden;
}

.unknownValueOverlayIcon {
  opacity: 0.75;
  pointer-events: none;

  & > svg {
    width: 40%;
  }
}

@keyframes needsAttentionPulse {
  0% {
    background-color: $componentAutomaticOffColor;
  }
  50% {
    background-color: $dangerColor;
  }
  100% {
    background-color: $componentAutomaticOffColor;
  }
}
