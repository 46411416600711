@import '../../variables.scss';

.container {
  width: 2.443em;
  height: 2.443em;
  background-color: $componentAutomaticOffColor;
  border-radius: 100%;
  display: flex;
  justify-self: center;
  align-items: center;

  &.valveOpen {
    background-color: $componentAutomaticOnColor;
  }

  &.hasUnknownValue {
    transform: scale(1, 1);
  }

  &.needsAttention,
  &.valveOpen.needsAttention {
    background-color: $componentAutomaticOffColor;
    animation: needsAttentionPulse 500ms infinite;
  }
}

.unknownIcon svg {
  width: 50%;
}

.valveIcon {
  width: 100%;
  height: 100%;
  padding: 0.2em;
  fill: #dadada;
  transition: transform ease-in-out 0.25s;
}

.valveOpen {
  .valveIcon {
    fill: #fff;
  }
}

.valveClosed {
  .valveIcon {
    transform: rotate(90deg);
  }
}

.hasUnknownValue {
  .valveIcon {
    visibility: hidden;
  }
}

@keyframes needsAttentionPulse {
  0% {
    background-color: $componentAutomaticOffColor;
  }
  50% {
    background-color: $dangerColor;
  }
  100% {
    background-color: $componentAutomaticOffColor;
  }
}
