@import '../../variables.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  /* iOS Safari viewport bug fix */
  min-height: -webkit-fill-remaining;
}

.noscroll {
  overflow: hidden;
}

.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  flex: 0 0;
  z-index: 10;
  @include drop-shadow($height: 2);
}

.sideMenu {
  position: fixed;
  height: 100%;
  background-color: $appBackgroundColor;
  z-index: 20000;
  transition: right 0.2s ease;

  &.isOpen {
    right: 0;
    @include drop-shadow($height: 4);
  }
}

.footer {
  @include drop-shadow();
}

.contentFooterContainer {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.content {
  flex: 1 1 100%;
  position: relative;
  display: flex;

  .autoScale & {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5em;

    font-size: clamp(0.71rem, 1.25vw, calc(1.67vh - 1px));

    @include for-tablet-portrait-down {
      font-size: clamp(0.75rem, 3vw, 1rem);
    }
  }

  .traditional & {
    width: auto;
    padding: 0 15px;
    margin: 0 auto;
  }
}

@include for-phone-only {
  .content {
    .autoScale & {
      justify-content: flex-start;
    }

    .traditional & {
      padding: 0 30px;
      margin: 0;
    }
  }

  .sideMenu {
    position: fixed;
    right: -100%;
    width: 100%;
  }
}

@include for-tablet-portrait-up {
  .traditional .content {
    width: 618px;
  }

  .sideMenu {
    right: -30em;
    width: 30em;
  }
}

@include for-tablet-landscape-up {
  .traditional .content {
    width: 880px;
  }
}

@include for-desktop-up {
  .traditional .content {
    width: 1170px;
  }
}

@include for-big-desktop-up {
}
