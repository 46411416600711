@import '../../../../variables.scss';
@import '../../../../components/Pipe/animations.scss';

.configureSensorButton {
  @include button($componentAutomaticOffColor);
  margin-right: 0.15em !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.629em;
  width: 1.629em;
  border-radius: 15%;
  padding: 0;
}

.slideDown {
  margin-bottom: -15em;
}

.sensorOptionsContainer {
  display: flex;
  flex-direction: row;
  margin-right: 0 !important;
}

.container {
  position: relative;
  height: 100%;
  display: flex;
}

.zoneAndStackContainer {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.collectorTankInPipeContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1; // modified
}

.zoneTankContainer {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5em;
  width: 28.5em;
}

.controlContainer {
  position: relative;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.commandButtonsContainer {
  margin: 0 1em 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .commandButton {
    width: 6.5em;
    height: 3.5em;
    font-size: 0.9em;
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 1em;
    }

    .valveTestIcon {
      width: 3.25em;
    }
  }

  .harvestScheduleInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 4.75em;

    .harvestScheduleInfoIcon {
      display: block;
      width: 2.75em;
      height: 2.75em;
    }

    .harvestScheduleInfoText {
      font-size: 0.8em;
    }
  }

  .cancelHarvestScheduleButton {
    margin-top: 0.5em;
    width: 8em;
    font-size: 0.8em;
  }
}

.zoneTank {
  width: 6.525em;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 1.25em;
}

.zoneTankInPipeHorizontal {
  position: absolute;
  right: 0;
  top: 4.75em;
  width: 12.495em;
  z-index: 3; //modified
  @include pipe-flows-right();
}

.zoneTankFullPipeHorizontal {
  position: absolute;
  left: 0;
  margin-left: -2.35em;
  top: 4.75em;
  width: 31em;
  z-index: 3; //modified
  @include pipe-flows-right();
}

.zoneTankFullMobilePipeHorizontal {
  position: absolute;
  right: 0;
  transform: translate(2px, 0px);
  top: 3em;
  width: 1.8em;
  z-index: 3; //modified
  @include pipe-flows-right();
}

.zoneTankOutPipeHorizontal {
  position: absolute;
  left: 0;
  top: 4.75em;
  width: 10.39em;
  z-index: 3; // modified
  @include pipe-flows-right();
}

.zoneTankOutPipeVertical {
  position: absolute;
  right: -0.218em;
  top: 4.75em;
  height: $dropSize * 14;
  @include pipe-flows-down();
  @include offset-pipe-animation(-0.65s);

  &.cleanInPlace {
    height: $dropSize * 6;
    top: 8.85em;
  }
}

.levelContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.levelFeederPipeContainer {
  position: relative;
}

.levelFeederPipe {
  position: absolute;
  height: $dropSize * 6;
  top: $dropSize * -4.5;
}

@for $i from 0 through 15 {
  ##{$i} .levelFeederPipe {
    @include offset-pipe-animation('-#{$i * 0.5}s');
  }
}

.levelWaterIn {
  .levelFeederPipe {
    @include pipe-flows-down();
  }
}

.levelWaterOut {
  --water-color: #{$dirtyWaterColor};

  .levelFeederPipe {
    z-index: 1; // modified
    @include pipe-flows-down();
  }
}

.levelIsBeingIrrigated {
  .levelOutPipe {
    z-index: 2; // modified
  }
}

.firstLevel {
  .levelFeederPipe {
    height: $dropSize * 5;
  }
}

.lastLevel {
  .levelWaterOut {
    .levelFeederPipe {
      display: none;
    }
  }
}

.levelInPipe {
  width: 0.5em;
  @include pipe-flows-left();
}

.levelInValve {
  transform: rotate(90deg);
  width: 2em;
  height: 2em;
  margin: 0.221em 0;
  z-index: 2;
}

.formCip {
  z-index: 9999 !important;
  width: 100%;
}

.EditButton {
  position: absolute;
  cursor: pointer;
  width: 1em;
  height: 1em;
  background: transparent;
  border: none;
  z-index: 5000;
  color: #ffffff;
}

.actionButton {
  position: absolute;
  width: 2em;
  height: 2em;
  border: 0;
  border-radius: 100%;
  background: rgb(92, 92, 92);
  color: #ffffff;
  transform: translate(3em);
}

.levelOutPipe {
  --water-color: #{$dirtyWaterColor};
  width: 1em;
  z-index: 0; // modified
  @include pipe-flows-left();
  @include offset-pipe-animation(-0.4s);
}

.level {
  width: 20.357em;
  height: $dropSize * 4;
  margin: ($dropSize / 2) 0;
  padding: 0 0 0 1.5em;
  background: #ffffff0a !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;

  & > * {
    margin-right: 1em;
  }
}

.levelTitle {
  font-size: 0.977em;
  height: 1.629em;
  width: 1.629em;
  left: -0.814em;
  top: 0.1em;
}

.levelSwitchContainer {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  top: -0.04em;
  margin-right: 1em;
}

.levelPlant {
  position: relative;
  flex: 1;
  width: 0;
}

.collectorTankInPipeVertical,
.collectorTankInPipeHorizontal,
.collectorTankOutPipeVertical,
.collectorTankOutPipeHorizontal,
.zoneTankInPipeHorizontal,
.zoneTankInPipeHorizontalMobile {
  --water-color: #{$dirtyWaterColor};
}

.collectorTankOutPipeHorizontal {
  &.cleanInPlace {
    --water-color: #{$waterColor};
  }
}

.collectorTankOutPipeVertical {
  z-index: 4;
}

.stackTank {
  margin-top: 0.6em;
  width: 9em;
  z-index: 3;
}

@include for-tablet-portrait-down {
  .container {
    flex-direction: column;
  }

  .levelContainer {
    margin-right: 1.75em;
  }

  .levelOutPipe {
    width: 1em;
  }

  .stackCommands {
    flex-direction: row;
    margin-bottom: 2em;
    justify-content: center;

    & > button {
      margin-bottom: 0 !important;
      margin: 0.5em;
      width: 5em;
      height: 5em;

      span {
        display: none;
      }
    }
  }

  .cleanInPlaceDetourPipe {
    position: absolute;
    z-index: 1;
    width: 1.75em;
    top: 8.85em;
    right: -0.17em;
    @include pipe-flows-left();

    --water-color: #{$waterColor};
  }

  .zoneTankInPipeHorizontal {
    display: none;
  }

  .zoneTankInPipeHorizontalMobile {
    position: absolute;
    z-index: 0;
    width: 10.35em;
    top: 3.35em;
    left: 0;
    @include pipe-flows-left();
  }

  .zoneTankOutPipeHorizontal {
    width: 8.96em;
  }

  .zoneTankOutPipeVertical {
    right: 1.22em;

    &.cleanInPlace {
      top: 8.85em;
      height: $dropSize * 6;
    }
  }

  .collectorTankInPipeHorizontal {
    position: absolute;
    bottom: 4em;
    width: 10.039em;
    @include pipe-flows-right();
  }

  .collectorTankInPipeVertical {
    position: absolute;
    bottom: 4em;
    height: 10em;
    @include pipe-flows-down();
    @include offset-pipe-animation(-0.6s);
  }

  .collectorTankOutPipeHorizontal {
    position: absolute;
    z-index: 4;
    bottom: 4em;
    right: -0.125em;
    width: 10.039em;
    @include pipe-flows-right();
  }

  .collectorTankOutPipeVertical {
    position: absolute;
    z-index: 3;
    bottom: 4em;
    height: calc(100% - 7.35em);
    right: -0.125em;
    @include pipe-flows-up();

    &.cleanInPlace {
      --water-color: #{$waterColor};
      height: calc(100% - 12.85em);
    }
  }

  .stackModeMaintenance .collectorTankInPipeVertical {
    height: 11.5em;
    @include offset-pipe-animation(0s);
  }

  .levelWaterOut {
    .levelFeederPipe {
      top: -2.14em;
    }
  }
}

@include for-tablet-landscape-up {
  .stackCommands {
    position: absolute;
    order: 2;
    margin-top: 4.85em;
    right: -8.75em;
    font-size: 0.8em;
  }

  .stackTank {
    display: none;
  }

  .cleanInPlaceDetourPipe {
    position: absolute;
    z-index: 2;
    width: calc(100% + 2.6em);
    left: -2.37em;
    top: 8.85em;
    @include pipe-flows-right();

    --water-color: #{$waterColor};
  }

  .collectorTankInPipeVertical {
    display: none;
  }

  .collectorTankInPipeHorizontal {
    position: absolute;
    width: 3.975em;
    right: 28.231em;
    bottom: 1em;
    @include pipe-flows-left();
  }

  .collectorTankOutPipeHorizontal {
    position: absolute;
    z-index: 5;
    width: 1.875em;
    right: 30.331em;
    bottom: 2.977em;
    @include pipe-flows-right();
  }

  .collectorTankOutPipeVertical {
    position: absolute;
    z-index: 4;
    height: calc(100% - 7.752em);
    right: 30.331em;
    bottom: 2.977em;
    @include pipe-flows-up();

    &.cleanInPlace {
      --water-color: #{$waterColor};
      height: calc(100% - 11.855em);
    }
  }

  .zoneTankInPipeHorizontalMobile {
    display: none;
  }

  .levelOutPipe {
    width: 1.25em;
  }

  .levelOutPipeLong {
    width: 4.5em;
  }

  .firstLevel {
    .levelWaterOut {
      .levelFeederPipe {
        z-index: 2; // modified
      }
    }
  }
}
