@import '../../variables.scss';

select:global(.form-control).select {
  width: auto;
  text-transform: uppercase;
  color: #a9a9a9;
  border: 0.2em solid transparent;
  height: 2em;
  font-size: 2em;
  font-weight: bold;
  background: none;

  &:focus {
    box-shadow: none;
    border-color: transparent;
  }

  &.multiple {
    font-size: 1.5em;
    padding-top: 0.05em;
    border-color: $componentAutomaticOnColor;
    background: $navigationBackgroundColor
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='#{encode-color($appTextColor)}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.5rem center/0.5em 2em;

    &:focus {
      box-shadow: none;
      border-color: adjust-color(
        $color: $componentAutomaticOnColor,
        $lightness: 10%
      );
    }
  }
}
