@import '../../../../../../../../variables.scss';

.buttonGroup {
  font-size: 0.75em;
  width: 25em;
}

.select {
  font-size: 0.9em;
  background-color: $appBackgroundColor !important;
  color: $buttonTextColor !important;
  border-color: $notActiveLowContrastColor !important;
  box-shadow: none !important;
  text-align-last: center;
}

@include for-tablet-portrait-down {
  .buttonGroup {
    display: none;
  }
}

@include for-tablet-landscape-up {
  .select {
    display: none;
  }
}
