.modal {
  max-width: 40em;
}

.titleIcon {
  font-size: 0.5em;
}

.timeContainer {
  width: 17em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.harvestStartTime {
  width: 8em;
  margin: 0 0.5em 0 0.5em;
}

.period {
  width: 6em;
}

.zonePostHarvestAction {
  width: 17em;
}
