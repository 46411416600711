.container {
  position: relative;
  width: 0.5em;
  margin: 0em 1em;
  z-index: 1;
  border-radius: 0.25em;
  border: 1px solid #888888;
}

.pointer {
  transition-duration: 0.4s;
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: -0.8em;
  width: 0;
  height: 0;
  border-top: 0.25em solid transparent;
  border-bottom: 0.25em solid transparent;
}

.sectors {
  border-radius: 0.25em;
  overflow: hidden !important;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 0.35em;
  height: 100%;
}

.sector {
  position: absolute;
  z-index: 500;
  bottom: 0;
  left: 0;
  width: 100%;
}
