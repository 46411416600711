@import '../../variables.scss';

.modal {
  max-width: 30em;
}

.modalBody {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: 1em;
  }
}

.titleContainer {
  display: flex;

  .icon {
    font-size: 0.7em;
    margin-right: 0.5em;
  }
}

.disclaimer {
  display: flex;

  .icon {
    margin: 0.25em 0.5em 0 0;
  }
}

.buttonContainer {
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 1.6em;

  & > button:not(:last-child) {
    margin-right: 1em;
  }
}

.sendingCommand {
  .buttonContainer {
    button {
      visibility: hidden;
    }
  }
}
