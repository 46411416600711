@import '../../variables.scss';

$borderRadius: 0.25em;

.levelSensorsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 1em 0;

  .levelSensor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:not(:last-child) {
      margin: 0 0 0.5em 0;
    }

    .label {
      position: relative;
      display: flex;
      z-index: 1;
      width: 8.5rem;
      height: 2.25rem;
      font-weight: bold;
      text-transform: uppercase;

      .labelName {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        &.enabled {
          top: -0.15em;
        }
      }

      .labelInner {
        position: absolute;
        background-color: $componentAutomaticOffColor;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: stretch;
        border-radius: $borderRadius;
      }

      .labelWaves {
        position: relative;
        height: 7%;
        mask: url(../../assets/images/wave.png);
        background-color: $dirtyWaterColor;
        mask-position: 0 0;
        mask-repeat: repeat-x;
        animation: wave-animation 60s linear 0s infinite;
        mask-size: 18% 180%;

        @keyframes wave-animation {
          to {
            mask-position: 2050% 0;
          }
        }
      }

      .labelLevel {
        background-color: $dirtyWaterColor;
        width: 100%;
        height: 40%;
        transition: height 1s ease-out;
        border-bottom-left-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;
      }
    }

    .indicator {
      display: flex;
      z-index: 1;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 2.25rem;
      height: 2.25rem;
      font-size: 1.5em;
      color: $navigationBackgroundColor;
      background-color: $componentAutomaticOffColor;

      &.enabled {
        background-color: $dirtyWaterColor;
      }

      &.disabled {
        background-color: $componentAutomaticOffColor;
      }
    }

    .sensorLabelConnector {
      display: flex;
      z-index: 0;
      width: 1.25em;
      height: 0.35em;
      margin: 0 -0.15em;
      background: $componentAutomaticOffColor;
    }
  }
}
