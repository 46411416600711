@import '../../variables.scss';

.button {
  position: absolute;
  right: 0;

  &:hover {
    color: $hoverTextColor;
  }
}
