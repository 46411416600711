@import '../../variables.scss';

.modal {
  max-width: 50em;
}

.modalContentContainer {
  min-height: 10em;
  max-height: 69vh;

  @include for-phone-only {
    max-height: 50vh;
  }
}

.permissionFormGroup {
  width: 100%;
}
