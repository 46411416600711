.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 1em;
  }

  & > button {
    width: 7.5em;
    height: 7.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      margin: 0 0 0.5em 0;
      font-size: 1.5em;
    }

    span {
      font-size: 0.75em;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}
