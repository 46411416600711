@import '../../variables.scss';

.container {
  position: relative;
}

.identifier {
  position: absolute;
  top: 0.125em;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0.75;
  pointer-events: none;

  .entity {
    opacity: 0.75;
    font-size: 0.65em;
    margin-bottom: -0.5em;
    pointer-events: none;
  }

  .name {
    font-size: 1.65em;
    pointer-events: none;
  }
}

div.unknownIcon {
  right: -31%;
  top: -27%;
  transform: scale(0.75);
  pointer-events: none;
}

div.volumeValue {
  align-items: flex-end;
  justify-content: flex-start;
  font-size: 0.75em;
  top: 5%;
  right: 7%;
  opacity: 0.75;
  pointer-events: none;
}

.volumeUnit {
  display: none;
}
