@import '../../../../variables.scss';

.container {
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 0.5em;
  }

  label {
    margin-bottom: initial;

    &.unknownRecipe {
      font-style: italic;
    }
  }

  .colorWheelImage {
    width: 1.75em;
  }
}
