@import '../../variables.scss';

.unknownValueIcon {
  padding-top: 8em;
  width: 20em;
}

.container {
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .gauge {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2em;

    .label {
      position: absolute;
      top: 2.5em;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.65em;
      line-height: 1em;
    }

    .metricColor {
      content: '';
      width: 2em;
      height: 0.35em;
      margin-top: 0.2em;
      margin-bottom: -0.05em;
      border-radius: 10em;
      background-color: var(--metric-color);
    }

    .unit {
      font-size: 0.65em;
    }

    .valueContainer {
      position: absolute;
      bottom: 0.75em;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;

      .value {
        font-size: 3em;
        color: $buttonTextColor;

        &.unknown {
          visibility: hidden;
        }
      }
    }

    svg {
      width: 18em;
      margin-top: -1.75em;
      z-index: 1;

      text {
        font-size: 0.01em;
        fill: $buttonTextColor;
      }

      path {
        &.needle {
          fill: $buttonTextColor;
          transform: rotate(var(--needle-angle));
          transition: transform 0.5s;
          animation: needle 0.5s cubic-bezier(0.54, 1.23, 0.48, 1.1);
        }

        &.unknownRange {
          fill: currentColor;
        }

        &.normalRange {
          fill: $successColor;
        }

        &.warningRange {
          fill: $warningColor;
        }

        &.dangerRange {
          fill: $dangerColor;
        }
      }
    }
  }
}

@keyframes needle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(var(--needle-angle));
  }
}
