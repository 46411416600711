@import '../../variables.scss';

.container {
  height: auto;
  max-height: 7rem;
  overflow: auto;

  label {
    display: flex;
    align-items: center;

    input[type='checkbox'] {
      position: relative;
      margin: 0 0.5rem 0 0;
    }
  }
}
