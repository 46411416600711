@import '../../variables.scss';

.disabledMode {
  background-color: #dc3545 !important;
}

.disabledMode > svg {
  filter: invert(0);
}

.container {
  width: 2.443em;
  height: 2.443em;
  background-color: $componentAutomaticOffColor;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .snoozeIcon {
    width: 70%;
    height: auto;
  }

  &.pumpOn {
    background-color: $componentAutomaticOnColor;
  }

  &.hasUnknownValue {
    transform: scale(1, 1);
  }
}

.unknownIcon > svg {
  width: 50%;
}

.pumpIcon {
  width: 100%;
  height: 100%;
  margin: 0.2em;
  fill: #dadada;
}

.pumpOn {
  .pumpIcon {
    animation: pump-rotation-animation 2s infinite linear;
    fill: #fff;
  }

  @keyframes pump-rotation-animation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}

.hasUnknownValue {
  .pumpIcon {
    visibility: hidden;
  }
}
