@import '../../variables.scss';

.svg {
  color: $componentAutomaticOffColor;
  animation: needsAttentionPulse 500ms infinite;
}

@keyframes needsAttentionPulse {
  0% {
    color: $componentAutomaticOffColor;
  }
  50% {
    color: $dangerColor;
  }
  100% {
    color: $componentAutomaticOffColor;
  }
}
