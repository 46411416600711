@import '../../../../variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 0.75em;
  padding: 1em;

  > :not(:last-child) {
    margin-bottom: 1em;
  }

  .levelBeeSensorTitleContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 0.25em;

    svg {
      font-size: 1.5em;
      margin: 0.12em 0.65em 0 1.5em;
      color: $buttonTextColor;
    }

    .levelBeeSensorTitleText {
      display: flex;
      flex-direction: column;

      .levelBeeSensorTitle {
        color: $buttonTextColor;
      }

      .levelBeeSensorSerial {
        font-size: 0.75em;
      }
    }
  }

  .levelBeeSensorCommandsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.65em 0 1em 0;

    & > :not(:last-child) {
      margin-right: 1em;
    }

    button {
      width: 10.5em !important;
    }
  }

  .gauges {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.25em;

    .gauge {
      font-size: 0.65em;
      cursor: pointer;
    }
  }
}
