@import '../../variables.scss';

.container {
  width: 2.443em;
  height: 2.443em;
  background-color: $componentAutomaticOffColor;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.waterIsFlowing {
    background-color: var(--water-color);
  }

  &.hasUnknownValue {
    transform: scale(1, 1);
  }
}

.unknownIcon > svg {
  width: 50%;
}

.icon {
  width: 100%;
  height: 100%;
  margin: 0.35em;
  fill: $navigationBackgroundColor;
  visibility: hidden;
}

.waterIsFlowing {
  .icon {
    animation: rotation-animation 2s infinite linear;
    visibility: visible;
  }

  @keyframes rotation-animation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}

.hasUnknownValue {
  .icon {
    visibility: hidden;
  }
}
