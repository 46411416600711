@import '../../../../variables.scss';

.btnSemilight {
  color: #ffffff;
  background-color: #5c5c5c;
  border-color: #5c5c5c;
}

.readonlyLightContainerActive {
  z-index: 400 !important;
  transition-duration: 0.5s;
  position: absolute;
  top: 0;
  left: 1.5em;
  background-color: #dddddd;
  border: #232221 1px;
  border-radius: 0.5em;
  visibility: visible;
  padding-left: 2em;
  text-align: right;
}

.lightContainer {
  z-index: 400 !important;
  transition-duration: 0.5s;
  position: absolute;
  top: 0.1em;
  left: 2.5em;
  background-color: #5c5c5c;
  border: #232221 1px;
  border-radius: 0.5em;
  width: 0.25em;
  height: 2em;
  visibility: hidden;
  overflow: hidden;
}

.lightContainer > .btn-group {
  width: 0.1em !important;
}

.activeLightContainer {
  z-index: 400 !important;
  transition-duration: 0.5s;
  position: absolute;
  top: 0;
  left: 1.5em;
  background-color: #5c5c5c;
  border: #232221 1px;
  border-radius: 0.5em;
  visibility: visible;
  padding-left: 2em;
  width: 5em;
  text-align: right;
}

.readonlyThumbKeyInactive {
  z-index: 500;
  position: absolute;
  background-color: #dddddd;
  padding: 0.25em;
  border-radius: 1.5em;
  margin-top: -0.25em;
  filter: invert(0.75);
}

.readonlyThumbKeyActive {
  z-index: 500;
  position: absolute;
  background-color: #dddddd;
  padding: 0.5em;
  border-radius: 1.5em;
  margin-top: -0.5em;
}

.readonlyThumbKeyActive > img {
  filter: invert(0.5);
}

.thumbKey {
  z-index: 500;
  position: absolute;
  background-color: #dddddd;
  padding: 0.5em;
  border-radius: 1.5em;
  margin-top: -0.5em;
  filter: invert(1);
  cursor: pointer;
}

.thumbKey > img {
  filter: invert(0.4);
}

.thumbKey:hover {
  z-index: 500;
  transition-duration: 0.4s;
  filter: invert(0.75);
  margin-top: -0.75em;
  box-shadow: 0 0.25em 1em #e9ecef;
  cursor: pointer;
}

.activeThumbKey {
  z-index: 500;
  position: absolute;
  background-color: #5c5c5c;
  border: #232221 1px;
  padding: 0.2em;
  border-radius: 1.5em;
  margin-top: -0.2em;
  cursor: pointer;
}

.activeThumbKey > img {
  filter: invert(1);
}

.activeThumbKey:hover {
  z-index: 500;
  transition-duration: 0.4s;
  filter: invert(0.25);
  box-shadow: 0 0 1em #232221;
  cursor: pointer;
}
