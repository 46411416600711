@import '../../../../../../variables.scss';
@import '../../../../../../components/Pipe/animations.scss';

$pipeOffset: $dropSize / 2;
$pipeOffsetDelay: -1.25s;

.invalidWaterSensorStateIcon {
  position: absolute;
  top: 0;
  left: 5%;
  width: 2.5em;
  height: 2.5em;
}

.ozoneSystemSelectorLandscape {
  position: absolute;
}

.ozoneModeSelectorLandscape {
  position: absolute;
}

.ozoneModeSelectorLandscapeSingular {
  position: absolute;
}

.tankSpace {
  display: flex;
  flex-direction: row;
  position: relative;
  padding-top: 100%;
  width: 100%;
  border-radius: 0.25em;
  position: relative;
  width: 6.5em;
  background-color: #3d3c3b;
}

.tankGauge {
  position: absolute;
  top: 0.75em;
  left: 0;
  right: 0;
}

@include for-tablet-portrait-down {
  .pumpOrp {
    position: absolute;
    top: 0;
    transform: translate(15em, 5.25em);
  }

  .container {
    position: relative;
    flex: 1;
  }

  .ozoneSystem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 8em 0 2em 0;
    min-height: 25vh;

    label {
      font-size: 0.75em;
      cursor: pointer;
    }
  }

  .feederPipeContainer {
    $height: $dropSize * 24;

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: $height;

    .feederPipe {
      flex: 1;
      position: relative;
      bottom: -$pipeOffset;

      &.top {
        z-index: 1;
      }

      &.left,
      &.right {
        display: none;
      }
    }

    &.isLast {
      height: ($height / 2) - ($dropSize / 2.3);
      margin-bottom: ($height / 2) - ($dropSize / 2.3);

      .feederPipe.bottom {
        display: none;
      }
    }
  }

  .reverseOsmosisPipe {
    display: none;
  }

  .zonesContainer {
    margin-top: 4em;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .zoneContainer {
      display: flex;
      flex-direction: row;
      align-items: center;

      &:first-child .zoneTank {
        margin-left: 0;
      }

      &:last-child .zoneTank {
        margin-right: 0;
      }

      &:first-child .tankSpace {
        margin-left: 0;
      }

      &:last-child .tankSpace {
        margin-right: 0;
      }

      .zoneReverseOsmosisComponents {
        --water-color: #{$pureWaterColor};
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .feederPipeContainer {
          .feederPipe {
            @include pipe-flows-down();
          }

          &.isFirst .feederPipe.top {
            margin: -15.25em 0;
          }
        }

        .zoneValvePipe {
          @include pipe-flows-right();
          width: $dropSize * 2 !important;
        }

        .zoneValveSwitch {
          & + .zoneValvePipe {
            width: $dropSize !important;

            & + .zoneValve {
              & + .zoneValvePipe {
                width: $dropSize * 2 !important;
              }
            }
          }
        }

        .zoneValve {
          transform: rotate(90deg);
        }

        .zoneValveUnknownIcon {
          transform: rotate(-90deg);
        }
      }

      .zoneTankContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .zoneStacksContainer {
          position: absolute;
          top: 6.5em;
          display: flex;
          flex-direction: column;
          align-items: center;

          .zoneToStacksPipe {
            @include pipe-flows-down();
            height: $dropSize * 2 !important;
          }

          .stacks {
            --water-color: #{$dirtyWaterColor};
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            .stacksIcon {
              width: 2.8em;
            }

            .zoneOzoneBypass {
              position: absolute;
              top: -1em;
              left: 4em;
              display: flex;
              flex-direction: column-reverse;
              align-items: center;

              .bypassValveContainer {
                position: relative;
                z-index: 2;

                .bypassValve {
                  transform: rotate(90deg);
                  z-index: 1;
                }

                .bypassValveUnknownIcon {
                  transform: rotate(-90deg);
                }

                .bypassValveSwitchContainer {
                  position: absolute;
                  display: flex;
                  align-items: center;
                  margin-top: 0.25em;
                }

                .manualBypassIndicator {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 50%;
                  width: 2.443em;
                  height: 2.443em;
                  margin: auto;
                  background: white;
                  border: 0.25em solid $dangerColor;

                  &::after {
                    content: 'M';
                    color: $dangerColor;
                    font-weight: bold;
                  }

                  ~ .bypassValve {
                    position: absolute;
                    left: 2.65em;
                    top: 0;
                    bottom: 0;
                    width: 1.5em;
                    height: 1.5em;
                    margin: auto;
                  }

                  ~ .bypassValveSwitchContainer {
                    margin-top: -0.1em;
                    margin-left: 2.15em;
                  }
                }
              }

              .stacksOutPipe {
                position: absolute;
                top: 2.15em;
                right: 2.5em;
                @include pipe-flows-right();
                width: $dropSize * 2.4 !important;
              }

              .zoneReturnPipe {
                @include pipe-flows-up();
                height: $dropSize * 2.4 !important;
              }

              .zoneBypassValveOutPipe {
                @include pipe-flows-down();
                height: $dropSize * 5 !important;

                &.waterIsFlowing {
                  z-index: 2;
                }
              }
            }
          }
        }
      }

      .zoneToOzoneComponents {
        --water-color: #{$dirtyWaterColor};
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        top: 5.65em;
        left: -0.1em;

        .zoneBypassValveOutPipe {
          @include pipe-flows-right();
          width: $dropSize * 5 !important;

          &.waterIsFlowing {
            z-index: 2;
          }
        }

        .feederPipeContainer {
          position: relative;
          left: -0.49em;

          .feederPipe {
            @include pipe-flows-up();
          }

          &.isFirst .feederPipe.top {
            margin: -4.65em 0;
            @include offset-pipe-animation(-2.75s);
          }

          &.isLast .feederPipe.top {
            @include offset-pipe-animation(-2.75s);
          }
        }
      }

      .ozoneToZoneComponents {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        margin: 0 0 -5em -2.9em;

        .zonePumpOutPipe {
          @include pipe-flows-left();
          width: $dropSize * 7 !important;
          z-index: 1;
        }

        .zonePump {
          transform: scaleX(-1);
        }

        .zonePumpUnknownIcon {
          transform: scaleX(-1);
        }

        .zonePumpSwitch {
          z-index: 2;
        }

        .zonePumpInPipe {
          @include pipe-flows-left();
          width: $dropSize * 1 !important;
          z-index: 1;
        }

        .zonePumpSwitchInPipe {
          @include pipe-flows-left();
          width: $dropSize * 3 !important;
        }

        .feederPipeContainer {
          position: relative;
          left: -0.45em;

          .feederPipe {
            @include pipe-flows-down();
          }
        }
      }
    }
  }

  .ozoneTankContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: -4.25em 0 0 -5.5em;

    .zonesToOzoneTankPipeContainer {
      --water-color: #{$dirtyWaterColor};
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 5.25em;
      top: 9.5em;
      left: -3.5em;

      .bypassValve {
        z-index: 2;
        transform: rotate(90deg);
      }

      .bypassValveUnknownIcon {
        transform: rotate(-90deg);
      }

      &.hasValve {
        width: 6.5em;
        top: 8.5em;
        left: -4.75em;
      }

      .zonesToOzoneTankPipe {
        flex: 1;
        @include pipe-flows-right();
      }
    }

    .ozoneTankInPipe {
      --water-color: #{$dirtyWaterColor};
      position: absolute;
      @include pipe-flows-up();
      height: 2.45em;
      top: 7.05em;
      left: 1.25em;
    }

    .ozoneTankOutPipe {
      position: absolute;
      @include pipe-flows-down();
      @include offset-pipe-animation(-0.1s);
      height: 4em;
      top: 7em;
      left: 4.55em;
      z-index: 1;
    }

    .ozoneTank {
      margin-top: 0.5em;
      width: 6.5em;
    }

    .switchesContainer {
      position: absolute;
      height: 6em;
      top: 0.8em;
      right: 13.25em;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;

      .switchContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 0.57em;
        }

        label {
          flex: 1 0 9em;
          margin: 0 1em 0 0;
          text-align: right;
        }
      }
    }

    .ozoneSystemSelectorPortrait {
      position: absolute;
      top: -2.75em;
      right: 0;
      width: 15em;
      transform: translate(-6em, 0);
    }

    .ozoneModeSelectorPortrait {
      position: absolute;
      top: -2.75em;
      right: 0;
    }

    .ozoneModeSelectorPortraitSingular {
      position: absolute;
      left: -12.5em;
      width: 100%;
      top: -2.75em;
    }
  }

  .ozoneSystemSelectorLandscape {
    display: none !important;
  }

  .ozoneModeSelectorLandscape {
    display: none !important;
  }

  .ozoneModeSelectorLandscapeSingular {
    display: none !important;
  }
}

@include for-tablet-landscape-up {
  .pumpOrp {
    position: absolute;
    bottom: 0;
    transform: translate(8.5em, -2em);
  }

  .container {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;
    margin-top: -4.55em;
  }

  .ozoneSystem {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 60em;
    top: 1.5em;
    right: 1.5em;

    label {
      font-size: 0.75em;
      cursor: pointer;
    }
  }

  .feederPipeContainer {
    $width: $dropSize * 24;

    display: flex;
    justify-content: center;
    width: $width;

    .feederPipe {
      flex: 1;
      position: relative;

      &.top,
      &.bottom {
        display: none;
      }
    }

    &.isFirst {
      width: ($width / 2) - ($dropSize / 2.3);
      align-self: flex-end;

      .feederPipe.left {
        display: none;
      }
    }

    &.isLast {
      width: ($width / 2) - ($dropSize / 2.3);
      align-self: flex-start;

      .feederPipe.right {
        display: none;
      }
    }

    &.mainJunctionIsRight {
      .feederPipe {
        left: -$pipeOffset;

        &.right {
          z-index: 1;
        }
      }

      &.isAdjacentToMainJunction {
        .feederPipe {
          @include offset-pipe-animation($pipeOffsetDelay);

          &.right {
            margin-left: -$pipeOffset;
            margin-right: -$pipeOffset;
          }
        }
      }
    }

    &.mainJunctionIsCenter {
      .feederPipe {
        @include offset-pipe-animation($pipeOffsetDelay);

        &.left {
          margin-left: -$pipeOffset;
        }

        &.right {
          margin-right: -$pipeOffset;
        }
      }
    }

    &.mainJunctionIsLeft {
      .feederPipe {
        right: -$pipeOffset;

        &.left {
          z-index: 1;
        }
      }

      &.isAdjacentToMainJunction {
        .feederPipe {
          @include offset-pipe-animation($pipeOffsetDelay);

          &.left {
            margin-left: -$pipeOffset;
            margin-right: -$pipeOffset;
          }
        }
      }
    }
  }

  .reverseOsmosisPipe {
    --water-color: #{$pureWaterColor};
    $height: 4.55em;
    @include pipe-flows-down();
    height: ($height + $dropSize) !important;
    top: -$height;
    z-index: 2;
    margin-bottom: $dropSize * -1;
  }

  .zonesContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .zoneContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      &:first-child .zoneTank {
        margin-left: 0;
      }

      &:last-child .zoneTank {
        margin-right: 0;
      }

      &:first-child .tankSpace {
        margin-left: 0;
      }

      &:last-child .tankSpace {
        margin-right: 0;
      }

      .zoneReverseOsmosisComponents {
        --water-color: #{$pureWaterColor};
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .feederPipeContainer {
          &.mainJunctionIsRight {
            .feederPipe {
              @include pipe-flows-left();
            }
          }

          &.mainJunctionIsCenter {
            .feederPipe.left {
              @include pipe-flows-left();
            }

            .feederPipe.right {
              @include pipe-flows-right();
            }
          }

          &.mainJunctionIsLeft {
            .feederPipe {
              @include pipe-flows-right();
            }
          }
        }

        .zoneValvePipe {
          @include pipe-flows-down();
          height: $dropSize * 2 !important;
        }

        .zoneValveSwitch {
          & + .zoneValvePipe {
            height: $dropSize !important;

            & + .zoneValve {
              & + .zoneValvePipe {
                height: $dropSize * 2 !important;
              }
            }
          }
        }
      }

      .zoneTankContainer {
        position: relative;
        display: flex;
        flex-direction: row;
        padding: 0 2.4em;

        .zoneStacksContainer {
          position: absolute;
          top: 0.25em;
          right: -1.4em;
          display: flex;
          flex-direction: row;
          align-items: center;

          .zoneToStacksPipe {
            @include pipe-flows-right();
            width: $dropSize * 2 !important;
          }

          .stacks {
            --water-color: #{$dirtyWaterColor};
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            .stacksIcon {
              width: 2.8em;
            }

            .zoneOzoneBypass {
              position: absolute;
              top: 2.75em;
              display: flex;
              flex-direction: column;
              align-items: center;

              .bypassValveContainer {
                position: relative;
                z-index: 1;
                display: flex;
                flex-direction: column;
                align-items: center;

                .bypassValve {
                  z-index: 1;
                }

                .bypassValveSwitchContainer {
                  display: flex;
                  flex-direction: column;
                  margin-top: $dropSize;
                }

                .manualBypassIndicator {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  border-radius: 50%;
                  width: 2.443em;
                  height: 2.443em;
                  margin: auto;
                  background: white;
                  border: 0.25em solid $dangerColor;

                  &::after {
                    content: 'M';
                    color: $dangerColor;
                    font-weight: bold;
                  }

                  ~ .bypassValve {
                    position: absolute;
                    top: 2.65em;
                    left: 0;
                    right: 0;
                    width: 1.5em;
                    height: 1.5em;
                    margin: auto;
                  }

                  ~ .bypassValveSwitchContainer {
                    position: absolute;
                    top: 4.65em;
                  }
                }
              }

              .stacksOutPipe {
                @include pipe-flows-down();
                height: $dropSize * 2 !important;
              }

              .zoneReturnPipe {
                position: absolute;
                top: 1.9em;
                right: 2.42em;
                @include pipe-flows-left();
                width: 50%;
              }

              .zoneBypassValveOutPipe {
                @include pipe-flows-down();
                height: $dropSize * 5 !important;

                &.waterIsFlowing {
                  z-index: 2;
                }
              }
            }
          }
        }
      }

      .zoneToOzoneComponents {
        --water-color: #{$dirtyWaterColor};
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        left: 5.625em;
        top: -0.1em;

        .zoneBypassValveOutPipe {
          @include pipe-flows-down();
          height: $dropSize * 5 !important;

          &.waterIsFlowing {
            z-index: 2;
          }
        }

        .feederPipeContainer {
          position: relative;
          top: -0.49em;
          $pipeOffsetDelay: 0s;

          &.isFirst .feederPipe,
          &.isLast .feederPipe {
            @include offset-pipe-animation(-0.75s);
          }

          &.mainJunctionIsRight {
            .feederPipe {
              @include pipe-flows-right();
            }

            &.isAdjacentToMainJunction {
              .feederPipe {
                @include offset-pipe-animation($pipeOffsetDelay);
              }

              &.waterFlowIsTerminal .feederPipe.right {
                margin-left: -$pipeOffset * 2.25;
                margin-right: -$pipeOffset * 2.25;
                z-index: 2;
              }
            }
          }

          &.mainJunctionIsCenter {
            &.isAdjacentToMainJunction {
              .feederPipe {
                @include offset-pipe-animation($pipeOffsetDelay);

                &.left {
                  @include pipe-flows-right();
                }

                &.right {
                  @include pipe-flows-left();
                }
              }
            }
          }

          &.mainJunctionIsLeft {
            .feederPipe {
              @include pipe-flows-left();
            }

            &.isAdjacentToMainJunction {
              .feederPipe {
                @include offset-pipe-animation($pipeOffsetDelay);
              }

              &.waterFlowIsTerminal .feederPipe.left {
                margin-left: -$pipeOffset * 2.25;
                margin-right: -$pipeOffset * 2.25;
                z-index: 2;
              }
            }
          }
        }
      }

      .ozoneToZoneComponents {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        right: -2em;
        top: -2.9em;
        margin-bottom: -2.5em;

        .zonePumpOutPipe {
          @include pipe-flows-up();
          height: $dropSize * 8 !important;
          z-index: 1;
        }

        .zonePump {
          transform: scaleX(-1);
          z-index: 2;
        }

        .zonePumpUnknownIcon {
          transform: scaleX(-1);
        }

        .zonePumpInPipe {
          @include pipe-flows-up();
          height: $dropSize * 1 !important;
          z-index: 1;
        }

        .zonePumpSwitchInPipe {
          @include pipe-flows-up();
          height: $dropSize * 3 !important;
        }

        .feederPipeContainer {
          position: relative;
          top: -0.45em;

          &.mainJunctionIsRight {
            .feederPipe {
              @include pipe-flows-left();
            }
          }

          &.mainJunctionIsCenter {
            .feederPipe.left {
              @include pipe-flows-left();
            }

            .feederPipe.right {
              @include pipe-flows-right();
            }
          }

          &.mainJunctionIsLeft {
            .feederPipe {
              @include pipe-flows-right();
            }
          }
        }
      }
    }
  }

  .ozoneTankContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    $zonesToOzoneTankPipeHeight: $dropSize * 24.8;

    .zonesToOzoneTankPipeContainer {
      --water-color: #{$dirtyWaterColor};
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: $zonesToOzoneTankPipeHeight;
      bottom: (-$zonesToOzoneTankPipeHeight + ($dropSize * 30)) - 0.15em;
      left: 8.4em;

      .bypassValve {
        z-index: 2;
      }

      &.hasValve {
        height: 13.2em;
        top: -9.1em;
        left: 7.4em;
      }

      .zonesToOzoneTankPipe {
        flex: 1;
        @include pipe-flows-down();
      }
    }

    .ozoneTankInPipe {
      --water-color: #{$dirtyWaterColor};
      position: absolute;
      @include pipe-flows-left();
      width: 2.45em;
      top: 3.6em;
      right: -2.4em;
    }

    .ozoneTankOutPipe {
      position: absolute;
      @include offset-pipe-animation($pipeOffsetDelay);
      @include pipe-flows-up();
      height: 1.81em;
      top: -1.35em;
      right: 0.76em;
      z-index: 1;
    }

    .ozoneTank {
      margin-top: 0.5em;
      width: 6em;
    }

    .switchesContainer {
      position: absolute;
      height: 100%;
      top: 0.3em;
      right: 12em;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;

      .switchContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 0.57em;
        }

        label {
          flex: 1 0 9em;
          margin: 0 1em 0 0;
          text-align: right;
        }
      }
    }

    .ozoneSystemSelectorPortrait {
      display: none !important;
    }

    .ozoneModeSelectorPortrait {
      display: none !important;
    }

    .ozoneModeSelectorPortraitSingular {
      display: none !important;
    }
  }

  .ozoneSystemSelectorLandscape {
    top: 0;
    left: 0;
    transform: translate(2.5em, -1.3em);
    z-index: 500;
  }

  .ozoneModeSelectorLandscape {
    top: 0;
    left: 0;
    transform: translate(23em, -1.3em);
    z-index: 500;
  }

  .ozoneModeSelectorLandscapeSingular {
    top: 0;
    left: 0;
    transform: translate(2em, -1.3em);
    z-index: 500;
  }
}
