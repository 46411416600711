$blip-width: 1.5em;
$blip-height: 1.5em;

.blip > div {
  display: block;
  width: $blip-width;
  height: $blip-height;
  border-radius: $blip-height;
  border: 4px solid rgba(255, 255, 255, 0.6);
  transition-duration: 0.4s;
}

.blip > input[type='color'] {
  position: absolute;
  width: $blip-width;
  height: $blip-height;
  opacity: 0;
  cursor: grab;
}

.blip > input[type='color']:hover + div {
  border: 1px solid rgba(255, 255, 255, 0.8);
}
