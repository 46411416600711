.modal {
  max-width: 35em;
}

.calibrationContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 27em;

  .manualInputContainer {
    width: 20em;
  }

  .calibrationModeLabel {
    margin-bottom: 0.25em;
  }

  .calibrationModeSwitch {
    margin: 0.25em 0 2em 0;
  }

  .sensorInput {
    width: 8em;
  }

  .calibrationButton {
    margin-left: 1.5em;
    background-color: #5586bb;
  }

  .clearCalibrationContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin: 3em 0 0 0;
    }
  }

  .calibrationDisplay {
    width: 15em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    margin: 1em 0 1em 0;

    .calibrationPointsHeading {
      align-items: flex-start;
    }

    .calibrationPointsGauge {
      height: 9em;
      width: 0.5em;
      border-radius: 2em;
      background-color: #444444;
      margin-right: 1em;
    }

    .calibrationPointsContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .calibrationPoint {
        position: relative;

        .calibrationPointIndicator {
          position: absolute;
          left: -1.4em;
          top: 50%;
          width: 1em;
          height: 2px;
          background-color: #444444;
        }

        .calibrationPointValue {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          height: 2em;
        }
      }
    }
  }

  :global(.invalid-feedback) {
    display: block;
    visibility: hidden;
    height: 1rem;
  }

  :global(.is-invalid) ~ :global(.invalid-feedback) {
    visibility: visible;
  }
}
