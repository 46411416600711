@import '../../../../variables.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 40.4em;
}

.reverseOsmosisSystem {
  margin-top: $panelPadding / 2;
  z-index: 1;
}

.detailContainer {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  @include panel();
}

.ozoneSystemNotFound {
  position: absolute;
}

@include for-tablet-portrait-down {
  .reverseOsmosisSystem {
    display: flex;
    justify-content: center;
  }
}
