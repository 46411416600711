@import '../../variables.scss';
@import '../../components/Pipe/animations.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @include panel();
}

.closeButton {
  top: 0;
  z-index: 1;
}

.levelCommands {
  @include for-tablet-landscape-up {
    position: absolute !important;
    order: 2;
    margin-top: 4.85em;
    right: 9.75em;
    font-size: 0.8em;
  }

  @include for-tablet-portrait-down {
    flex-direction: row;
    margin-bottom: 2em;
    justify-content: center;

    & > button {
      margin-bottom: 0 !important;
      margin: 0.5em;
      width: 7em;
      height: 7em;
    }
  }
}

.levelModeContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.71em;
  position: relative;

  .levelModeLabel {
    margin-bottom: 0.5em;
  }
}

.stackLight {
  width: 12em;
  margin-bottom: 1.5em;

  > img {
    width: 100%;
  }
}

.levelContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 3.5em;

  .zoneTank {
    width: 9em;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .levelPipe {
    @include for-tablet-landscape-up {
      @include pipe-flows-right();
    }

    @include for-tablet-portrait-down {
      @include pipe-flows-down();
    }
  }

  .levelWaterIn {
    --water-color: #{$waterColor};
  }

  .levelWaterOut {
    --water-color: #{$dirtyWaterColor};
  }

  .levelInOutPipe {
    @include for-tablet-landscape-up {
      width: 4.2em;
    }

    @include for-tablet-portrait-down {
      height: 2.2em;
    }
  }

  .levelFeederPipe {
    @include for-tablet-landscape-up {
      width: 1.5em;
    }

    @include for-tablet-portrait-down {
      height: 0.75em;
    }
  }

  .stackTank {
    width: 9em;
  }

  .level {
    padding: 0.5em;
    border: 1px solid $componentAutomaticOnColor;
  }

  @include for-tablet-portrait-down {
    flex-direction: column;
  }
}

.levelStatusContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-bottom: 0.75em;
}

.levelSensorReadings {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  justify-content: center;
  width: 100%;

  > * {
    margin-bottom: 1.5em;
  }

  .gaugeChartContainer {
    flex: 1;
    display: flex;

    .gauge {
      font-size: 0.75vmin;
    }

    .recharts-responsive-container {
      @include for-phone-only {
        display: none;
      }
    }
  }
}

.gaugeContainer {
  background-color: #383635;
  border-radius: 0.25em;
}

@include for-tablet-landscape-up {
  .container {
    max-width: 77.357em;
  }
}
