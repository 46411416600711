@import '../../variables.scss';

.svg {
  width: 3.5em;
  height: 3.5em;

  .valve {
    transform-origin: 61% 33%;
  }

  &.animate {
    .valve {
      animation-name: valveRotate;
      animation-duration: 6s;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes valveRotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(90deg);
  }
}
