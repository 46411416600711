@import '../../variables.scss';

.small {
  display: none;
}

@include for-phone-only {
  .small {
    display: initial;
  }
  .large {
    display: none;
  }
}
