.menuBtn {
  padding: 2.28em 1.629em;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuBtnIcon {
  height: 0.163em;
  width: 1.466em;
  position: relative;
  transition: background 0.2s ease-out;
}

.menuBtnIcon,
.menuBtnIcon:before,
.menuBtnIcon:after {
  background: currentColor;
  display: block;
}

.menuBtnIcon:before,
.menuBtnIcon:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
}

.menuBtnIcon:before {
  top: 0.407em;
}

.menuBtnIcon:after {
  top: -0.407em;
}

.open .menuBtnIcon {
  background: transparent;

  &:before {
    transform: rotate(-45deg);
    top: 0;
  }

  &:after {
    transform: rotate(45deg);
    top: 0;
  }
}
