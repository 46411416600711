@import '../../variables.scss';

$borderRadius: 5px;

.container {
  flex: 1;
  width: 100%;
  display: flex;
  margin: 2em 0;
  border-radius: $borderRadius;
  @include drop-shadow();
}

.navigation {
  flex: 0 0 25%;
  background-color: #232221;
  box-shadow: inset -5px 0 10px -3px rgba(0, 0, 0, 0.125);
  border-top-left-radius: $borderRadius;
  border-bottom-left-radius: $borderRadius;
}

.navigationItem {
  display: block;
  padding: 1em;
  font-size: 1.25em;
  font-weight: 500;

  transition: color #{$buttonTransition};

  .titleAndIconContainer {
    display: flex;
    align-items: flex-start;

    .titleIcon {
      margin-right: 0.75em;
    }
  }

  &.active {
    background-color: $navigationBackgroundColor;
    @include drop-shadow();
    margin-left: -0.5em;
    padding-left: 1.5em;
    border-top-left-radius: $borderRadius;
    border-bottom-left-radius: $borderRadius;
  }
}

.content {
  position: relative;
  flex: 0 0 75%;
  padding: 1em 2em 2em 2em;
  background-color: $navigationBackgroundColor;
  border-top-right-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
  overflow: hidden;

  .titleContainer {
    display: flex;
    align-items: center;

    .title {
      flex-basis: 100%;
      visibility: hidden;
    }

    .closeButton {
      padding: 1em 0;
    }
  }
}

.heading {
  .icon {
    margin-right: 0.5em;
  }
}

@include for-phone-only {
  .container {
    margin: 15px -15px;
  }

  .navigation {
    flex-basis: 5em;
  }

  .content {
    flex-basis: 85%;
    padding-top: 1.25em;

    .titleContainer {
      align-items: flex-start;

      .title {
        visibility: visible;
        margin-bottom: 1em;
      }
    }
  }

  .navigationItem {
    text-align: center;
    padding: 0.5em;

    .title {
      display: none;
    }

    .titleIcon {
      margin: 0;
    }

    &.active {
      margin-left: -0.5em;
      padding-left: 1em;
    }
  }
}
