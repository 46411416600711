@import '../../../../variables.scss';

$hexagonBottomMargin: 0.75em;

.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.pumpRoomNavigationContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    justify-content: flex-end;
  }

  &:last-child {
    justify-content: flex-start;
  }
}

.zoneNavigationContainer {
  font-size: 0.96em;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: -$hexagonBottomMargin;
  padding: 0.9em 0;
}

.buttonTitle {
  font-size: 0.35em;
  margin-bottom: -0.5em;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  opacity: 0.75;
}

.buttonLabel {
  width: 100%;
  z-index: 2;
}

.button {
  margin-bottom: $hexagonBottomMargin;

  &.pumpRoom {
    background-color: transparent;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: $buttonTextColor;
    font-size: 0.7em;
    font-weight: bold;
    margin: 2em;
    text-transform: uppercase;
    border: solid 0.4em $componentAutomaticOnColor;
    border-radius: 5em;
    width: 10em;
    height: 4em;
    z-index: 1;
    opacity: 0.65;

    transition: color #{$buttonTransition}, opacity #{$buttonTransition};

    &:hover,
    &.active {
      opacity: 1;
    }

    &.active {
      background-color: $componentAutomaticOnColor;
    }

    &.attentionLevelLow {
      background-color: $dangerColor;
      border-color: $dangerColor;
    }

    &.attentionLevelCritical {
      animation: needsAttentionPulse 500ms infinite;
    }
  }
}

@include for-tablet-portrait-down {
  .zoneNavigationContainer {
    padding: 2em $hexagonBottomMargin;
  }
}

@include for-phone-only {
  .container {
    flex-direction: column;
  }

  .zoneNavigationContainer {
    font-size: 1.2em;
    margin: -1.5em 0;
  }

  .button.pumpRoom {
    font-size: 0.9em;
    margin: 1.5em 0 0.5em 0;
  }
}

@keyframes needsAttentionPulse {
  0% {
    background-color: $componentAutomaticOffColor;
    border-color: $componentAutomaticOffColor;
  }
  50% {
    background-color: $dangerColor;
    border-color: $dangerColor;
  }
  100% {
    background-color: $componentAutomaticOffColor;
    border-color: $componentAutomaticOffColor;
  }
}
