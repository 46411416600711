@import '../../variables.scss';

.container {
  display: flex;

  .icon {
    margin-right: 0.5em;
  }

  .title {
    flex: 1;
  }
}
