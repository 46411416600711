@import '../../../../variables.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menuButtonContainer {
  display: flex;
  flex: 1 0 5.9em;
  width: 100%;
  justify-content: flex-end;
  align-content: center;
  padding-right: 0.6em;
  background-color: $navigationBackgroundColor;
}

.activityContainer {
  flex: 1 1 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  border-top: 1px solid $notActiveLowContrastColor;
}

.activityList {
  min-height: min-content;
}

.miscCommandsContainer {
  flex: 1 0 5.9em;
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 1.2em;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 0.5em;
    flex-basis: 100%;
  }
}

@include for-phone-only {
  .menuButtonContainer {
    flex: 1 0 6em;
  }
}
