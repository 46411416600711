@import '../../../../variables.scss';

.activeZoneContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  flex: 1;

  > :not(:last-child) {
    margin-bottom: $panelMargin;
  }

  button:global(.rounded) {
    width: 9.5em;
  }

  .zoneTankAndNutrientMixerPanel {
    @include panel();
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex: 1;

    > :not(:last-child) {
      margin-right: $panelMargin;
    }

    .nutrientMixerGauges,
    .nutrientMixerPumps {
      flex: 1 1 40%;
    }

    .zoneTankContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      & > :not(:last-child) {
        margin-bottom: 1em;
      }

      .invalidWaterLevelSensorStateIcon {
        position: absolute;
        width: 2.5em;
        height: 2.5em;
        top: 0;
        left: 3%;
      }

      .zoneModeLabel {
        margin-bottom: 0.5em;
      }

      .zoneTank {
        font-size: 1.2em;
        width: 7em;
        margin-bottom: 1em;
      }
    }
  }

  .stacksControlPanel {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-flow: row;
    align-items: center;
    justify-content: center;
  }

  .stacksPanel {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include panel();

    & > *:not(:last-child) {
      margin-bottom: 1.5em;
    }

    .stacksContainer {
      display: flex;
      justify-content: center;

      &.hasStackAttachedCollectorTanks {
        width: 100%;
      }

      .stackControlsContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:first-child {
          align-items: flex-end;
          margin: 0 1em 0 0;
        }

        &:last-child {
          align-items: flex-start;
          margin: 0 0 0 1em;
        }

        &.collectorTankContainer {
          margin: 0 0 0 0.25em;
          position: relative;
          align-items: center;
          justify-content: flex-end;

          .collectorTank {
            font-size: 0.65em;
            width: 10em;

            .zoneReturnLabel {
              margin-bottom: 1em;
            }
          }

          .collectorTankAlertsContainer {
            position: absolute;
            right: -8.25em;
            width: 5em;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: stretch;
          }

          .alertIcon {
            width: 100%;
            height: 100%;
          }

          .pumpTimeoutIcon {
            width: 5em;
            height: 5em;
            padding: 5%;
          }

          .collectorTankInvalidStateIcon {
            width: 5em;
            height: 5em;
            padding: 5%;
          }
        }
      }
    }
  }
}

.invalidWaterSensorStateIcon {
  margin: 5%;
}

@include for-tablet-portrait-down {
  .activeZoneContainer {
    button {
      font-size: 0.75rem !important;
    }

    .stacksPanel {
      .stacksContainer {
        flex-direction: column;
        align-items: center;

        .stackControlsContainer {
          &:first-child {
            margin: 0 0 1em 0;
          }

          &:last-child {
            margin: 0;
          }
        }

        &.hasZoneAttachedCollectorTank {
          .stackControlsContainer {
            &:first-child {
              margin: 0;
            }

            &.collectorTankContainer {
              margin: 1em 0 0 0;

              .collectorTankAlertsContainer {
                position: relative;
                order: -1;
                bottom: 0;
                right: 0;
                margin-bottom: 0.5em;
              }
            }
          }
        }
      }
    }

    .zoneTankAndNutrientMixerPanel {
      flex-direction: column;
      justify-content: stretch;
      align-items: center;

      > :not(:last-child) {
        margin-right: 0;
        margin-bottom: 3em;
      }

      .nutrientMixerGauges,
      .nutrientMixerPumps {
        flex: initial;
      }

      .zoneTankContainer {
        order: -1;
      }

      .nutrientMixerPumps {
        margin: 0;
        height: 20em;
        font-size: clamp(0.95em, 2.5vw, 1.25em);
      }

      .nutrientMixerGauges {
        font-size: clamp(0.95em, 2.5vw, 1.25em);
      }
    }
  }
}
