@import '../../variables.scss';

.beeDisplayButton {
  height: 1.529em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10em;
  margin-left: 0.2em !important;
  margin-right: 0 !important;
  position: relative;
  left: -0.5em;

  @include button($componentAutomaticOnColor);

  .reading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    svg {
      font-size: 1em;
      position: relative;
      left: -0.25em;
    }

    p {
      flex-grow: 1;
      font-size: 0.75em;
      margin: 0;
      text-align: center;
    }

    .unknownValue {
      color: $appTextColor;
    }
  }

  @include for-tablet-portrait-down {
    .reading {
      width: 2.7em;

      p {
        font-size: 0.75em;
      }

      .readingIcon {
        display: none;
      }
    }
  }

  @include for-tablet-landscape-up {
    .reading {
      width: 3.5em;
    }
  }
}
