@import '../../../../variables.scss';

$stackBorderColor: $notActiveColor;
$stackBottomMargin: 1em;

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -$stackBottomMargin;
}

.stack {
  position: relative;
  width: 4.8em;
  height: 9em;
  margin: 0.6em 0.25em $stackBottomMargin 0.25em;
  border: 0.2em solid $stackBorderColor;
  border-radius: 0.6em;

  .stackStatus {
    position: relative;
    margin-top: 1.1em;
    padding: 0.3em;
    height: 89.25%;
  }

  &.perStackCollectorTanks {
    height: 13em;

    .stackStatus {
      padding-bottom: 0;
    }
  }

  &.needsAttentionCritical {
    animation-name: needsAttentionCriticalBorderPulse;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-delay: var(--animation-sync-delay);
  }

  &.needsAttentionLow {
    border-color: $dangerColor;
  }
}

.stackTitleContainer {
  position: absolute;
  top: -0.814em;
  width: 100%;
  display: flex;
  justify-content: center;
}

.stackTitle {
  font-size: 0.977em;
  height: 1.629em;
  width: 1.629em;
  top: -0.977em;
  line-height: 1.466em;
  border-radius: 50%;
  color: $buttonTextColor;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $stackBorderColor;

  .needsAttentionCritical &,
  .needsAttentionLow & {
    border-color: $dangerColor;
    background-color: $dangerColor;
  }
}

@keyframes needsAttentionCriticalBorderPulse {
  0% {
    border-color: $stackBorderColor;
  }
  50% {
    border-color: $dangerColor;
  }
  100% {
    border-color: $stackBorderColor;
  }
}
