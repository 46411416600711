@import '../../../../variables.scss';

.container {
  background-color: $navigationBackgroundColor;
}

.nav {
  display: flex;
  align-items: stretch;
  padding: 0.57em;
  justify-content: space-evenly;
}

.brandImage {
  width: 8.143em;
  margin-left: 1.57em;
}

.navLeft {
  flex: 1 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

a.brand:hover svg path {
  fill: $buttonTextColor !important;
  stroke: $buttonTextColor !important;
}

.navCenter {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &,
  button {
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;

    &:hover,
    &:focus {
      outline: none;
    }
  }
}

.navRight {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.heading {
  font-size: 2em;
  margin-bottom: 0;

  &,
  button {
    font-weight: 500;
  }
}

.heading > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.heading img {
  height: 1.5em;
  margin-right: 0.407em;
}

@include for-phone-only {
  .brandImage {
    width: 4.723em;
  }
}
