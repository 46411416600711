@import '../../variables.scss';

.modal {
  max-width: 90em;
  display: flex;
  justify-content: center;

  .measurementsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 69vh;

    @include for-phone-only {
      max-height: 50vh;
    }

    .measurementContainer {
      display: flex;
      margin: 1.5em;
      max-width: 30em;

      .gauge {
        font-size: 0.75em;
        margin: -1.5em -2em 0 0;

        @include for-phone-only {
          font-size: 1.9vw;
        }
      }

      .measurementThresholds {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        & > :not(:last-child) {
          margin-bottom: 0.5em;
        }

        .measurementThreshold {
          display: flex;
          align-items: center;
          width: 15em;

          label {
            flex: 1 0 6em;
            margin: 0 1em 0 0;
            text-align: right;
          }

          input {
            flex: 1 1 100%;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
