@import '../../variables.scss';

.container {
  position: relative;

  .errorInfo,
  .resourceNotFoundInfo {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &.loading,
  &.error,
  &.resourceNotFound {
    > * {
      visibility: hidden;
    }
  }

  &.loading .loadingIcon,
  &.error .errorInfo,
  &.resourceNotFound .resourceNotFoundInfo {
    visibility: visible;
  }
}
