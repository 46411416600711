$notActiveColor: #444444;
$notActiveLowContrastColor: #555555;
$successColor: #8bc34a;
$warningColor: #ff9800;
$dangerColor: #dc3545;
$waterColor: #5586bb;
$pureWaterColor: adjust-color(
  $color: $waterColor,
  $lightness: 25%,
);
$dirtyWaterColor: #189589;
$borderWaterColor: #5586bb;
$backgroundWaterColor: #1e334a;
$buttonTextColor: #ddd;
$primaryButtonBackgroundColor: $waterColor;
$secondaryButtonBackgroundColor: $notActiveColor;
$lightBulbOnColor: #fad63b;
$appTextColor: #a9a9a9;
$hoverTextColor: #f8f9fa;
$appBackgroundColor: #2b2b2b;
$navigationBackgroundColor: #353332;
$navigationButtonBackgroundColor: $notActiveColor;
$pipeColor: $notActiveColor;
$pipeWidth: 0.489em;
$dropSize: $pipeWidth;
$componentAutomaticOnColor: #5c5c5c;
$componentAutomaticOffColor: $notActiveColor;
$componentManualOnColor: #627753;
$componentManualOffColor: $dangerColor;
$disabledFormControlTextColor: #e9ecef;
$disabledFormControlBackgroundColor: #333;
$disabledFormControlBorderColor: #555;

$buttonTransition: 0.15s ease-in-out;

@mixin drop-shadow($height: 1) {
  box-shadow: 0 (0.01rem * $height) (0.375rem * $height) (0.075rem * $height)
    rgba($color: #000000, $alpha: 0.125);
}

$panelMargin: 1em;
$panelPadding: 1.5em;

@mixin panel {
  background-color: $navigationBackgroundColor;
  border-radius: 0.5em;
  padding: $panelPadding;
  @include drop-shadow();
}

@function encode-color($string) {
  @if type-of($string) == 'color' and str-index(#{$string}, '#') == 1 {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote('#{$hex}');

    @return '%23' + $string;
  }

  @return $string;
}

@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-portrait-down {
  @media (max-width: 899px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1400px) and (min-height: 1000px) and (min-aspect-ratio: 129/100) {
    @content;
  }
}

@mixin button($backgroundColor) {
  &,
  &.disabled,
  &:disabled {
    background-color: $backgroundColor;
    border-color: $backgroundColor;
  }
  &:hover:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled),
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    background-color: adjust-color($color: $backgroundColor, $lightness: 10%);
    border-color: adjust-color($color: $backgroundColor, $lightness: 10%);
  }
  &:focus:not(:disabled):not(.disabled),
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    box-shadow: none !important;
  }
}

@mixin unify-parent($child) {
  @at-root #{selector-unify(&, $child)} {
    @content;
  }
}
