@import '../../variables.scss';

.modal {
  max-width: 40em;
}

.titleIcon {
  font-size: 0.5em;
}

.inputSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 0.75em;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }

  .secondsPerValveInput {
    width: 4.5em;
  }
}
