@import '../../../../../../variables.scss';

.statusIndicatorContainer {
  margin: 3em 0 0.5em 0 !important;
  width: 4em;
  height: 4em;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .statusIndicator {
    width: 4em;
  }

  .infoText {
    position: absolute;
    background: $appBackgroundColor;
    text-align: center;
    font-size: 0.75em;
    width: 16em;
    top: -5.25em;
    padding: 1em;
    border-radius: 0.5em;
    text-transform: uppercase;
    font-weight: bold;
    @include drop-shadow();

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -1.25em;
      margin: auto 0;

      width: 0;
      height: 0;
      $arrow-length: 1.25em;
      $arrow-height: 0.75em;
      border-left: $arrow-height solid transparent;
      border-right: $arrow-height solid transparent;
      border-top: $arrow-length solid $appBackgroundColor;

      transform: translate(-50%, 0);
    }
  }
}
