@import '../../variables.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
}

.icon {
  fill: currentColor;
  width: 4.071em;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  margin-left: 0.7em;
}

.message {
  font-size: 2em;
  text-transform: capitalize;
}

.detail {
  font-size: 0.85em;
  word-break: break-word;
}

@include for-phone-only {
  .container {
    margin-top: 2.28em;
  }
}
