.alert {
  display: flex;

  .icon {
    margin-top: 0.08em;
  }

  .text {
    align-self: center;

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  & > *:not(:last-child) {
    margin-right: 0.5em;
  }
}
