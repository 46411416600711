@import '../../variables.scss';

.pipe {
  --pipe-color: #{$pipeColor};
  --animation-duration: 4000s;
  --animation-sync-delay: 0s;
  height: $dropSize;
  width: $dropSize;
  animation-duration: var(--animation-duration);
  animation-timing-function: linear;
  animation-delay: var(--animation-sync-delay);
  animation-iteration-count: infinite;
  background-color: var(--water-color);

  &.notFlowing {
    background-image: none !important;
    background-color: var(--pipe-color);
    animation: none !important;
  }
}
