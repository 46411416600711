@import '../../variables.scss';

.modal {
  max-width: 77em;
  display: flex;
  justify-content: center;

  :global(.modal-body) {
    background-color: $appBackgroundColor;
    padding: 1.2em;
  }

  .calibrationValuesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0.5em 0;
    margin: -1em 0;

    @include for-phone-only {
      max-height: 60vh;
    }

    @include for-tablet-landscape-up {
      max-height: 69vh;
    }

    .calibrationValueContainer {
      display: flex;
      flex-direction: column;
      margin: 0.5em;
      padding: 0.75em 1em 0.35em 1em;
      width: 16em;
      background-color: $disabledFormControlBackgroundColor;
      border-radius: 0.5em;

      @include for-phone-only {
        width: 19em;
      }

      @include drop-shadow();

      .label {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.65em;
        line-height: 1em;
        margin: 0.125em 0 0.785em;

        .unknownValue {
          position: absolute;
          color: $componentAutomaticOnColor;
          right: 0;
          top: 0.1em;
        }

        .metricColor {
          content: '';
          width: 2em;
          height: 0.35em;
          margin-top: 0.2em;
          margin-bottom: -0.05em;
          border-radius: 10em;
          background-color: var(--metric-color);
        }

        .unit {
          font-size: 0.65em;
        }
      }

      :global(.form-group) {
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 0.75em;
        }

        :global(.input-group-append) {
          :global(.input-group-text) {
            width: 4em;
            text-align: center;
            display: inline-block;
          }
        }
      }
    }
  }
}
