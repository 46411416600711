.container {
  position: relative;
  top: 1em;
  width: 100%;
  height: 100%;
}

.area {
  opacity: 0.5;
}
